import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createBlock as _createBlock, withCtx as _withCtx, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-1458ea1e"),n=n(),_popScopeId(),n)
const _hoisted_1 = { key: 0 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_AppBar = _resolveComponent("AppBar")!
  const _component_ion_spinner = _resolveComponent("ion-spinner")!
  const _component_ion_col = _resolveComponent("ion-col")!
  const _component_ion_row = _resolveComponent("ion-row")!
  const _component_ion_grid = _resolveComponent("ion-grid")!
  const _component_ion_content = _resolveComponent("ion-content")!
  const _component_ion_page = _resolveComponent("ion-page")!

  return (_openBlock(), _createBlock(_component_ion_page, null, {
    default: _withCtx(() => [
      _createVNode(_component_AppBar),
      _createVNode(_component_ion_content, null, {
        default: _withCtx(() => [
          _createVNode(_component_ion_grid, null, {
            default: _withCtx(() => [
              _createVNode(_component_ion_row, { class: "ion-justify-content-center ion-align-items-center" }, {
                default: _withCtx(() => [
                  _createVNode(_component_ion_col, {
                    "size-xs": "12",
                    "size-sm": "10",
                    "size-md": "8",
                    "size-lg": "6",
                    "size-xl": "6",
                    class: "ion-text-center"
                  }, {
                    default: _withCtx(() => [
                      (_ctx.offline)
                        ? (_openBlock(), _createElementBlock("p", _hoisted_1, _toDisplayString(_ctx.errorMessage), 1))
                        : (_openBlock(), _createBlock(_component_ion_spinner, {
                            key: 1,
                            name: "crescent"
                          }))
                    ]),
                    _: 1
                  })
                ]),
                _: 1
              })
            ]),
            _: 1
          })
        ]),
        _: 1
      })
    ]),
    _: 1
  }))
}