
import { defineComponent, ref } from 'vue'
import { IonApp, IonRouterOutlet, alertController } from '@ionic/vue'
import AppLoading from '@/components/AppLoading.vue'
import useStore from '@/store'
import { showAlert, showLoading, showToast } from '@/lib/utils'


export default defineComponent({
  name: 'App',
  components: {
    IonApp,
    IonRouterOutlet,
    AppLoading
  },
  setup () {
    const isLoading = ref(true)
    const isOffline = ref(false)
    let registration: any = null
    let refreshing = false
    const store = useStore()

    const init = async (): Promise<void> => {
      try {
        await store.fetchMessages()
        await store.fetchMetiers()
        await store.fetchAides()
        await store.fetchResponsables()
        await store.fetchInformationsPratiques()
        await store.fetchSousInformationsPratiques()
        await store.fetchApresApprentissages()
        await store.fetchSousApresApprentissages()

        isLoading.value = false
      } catch (error) {
        isOffline.value = true
      }
    }

    const convertSecondsInHMS = (duration: number): { heures: number, minutes: number, secondes: number } => {
      const heures = Math.floor(duration / 3600)
      const resteMinutes = duration % 3600
      const minutes = Math.floor(resteMinutes / 60)
      const secondesRestantes = resteMinutes % 60

      return { heures, minutes, secondes: secondesRestantes }
    }

    const refreshData = async () => {
      const loading = await showLoading()
      await loading.present()

      try {
        await store.fetchMessages()
        await store.fetchMetiers()
        await store.fetchAides()
        await store.fetchResponsables()
        await store.fetchInformationsPratiques()
        await store.fetchSousInformationsPratiques()
        await store.fetchApresApprentissages()
        await store.fetchSousApresApprentissages()
      } catch (error) {
        const toast = await showToast(
          'Un problème est survenu.',
          'danger'
        )

        await toast.present()
      } finally {
        loading.dismiss()
      }
    }

    init()

    // Set the date of the last data loading.
    const currentDate = new Date()
    const serialzedDate = currentDate.toJSON()

    // Save the date
    localStorage.setItem('lastDataLoaded', serialzedDate)


    window.onpopstate = () => {
      alertController.dismiss()
    }

    document.addEventListener('swUpdated', async (event: any) => {
      registration = event.detail

      const alert = await showAlert(
        'VD Apprentissage',
        'Nouveau contenu disponible.',
        [
          {
            text: 'Actualisez maintenant',
            handler: () => {
              if (!registration || !registration.waiting) return
              registration.waiting.postMessage({ type: 'SKIP_WAITING' })
            }
          }
        ],
        false
      )

      alert.present()
    }, { once: true })

    if ('serviceWorker' in navigator) {
      navigator.serviceWorker.addEventListener('controllerchange', () => {
        if (refreshing || !registration) return
        refreshing = true
        location.reload()
      })
    }


    document.addEventListener('visibilitychange', async () => {
      if (document.visibilityState === 'visible') {

        // Read last load data
        const serializedDate = localStorage.getItem('lastDataLoaded')
        // const savedDate = new Date()
        if (serializedDate){
          const savedDate = new Date(serializedDate)
          const dateActuelle = new Date()

          // Calculate interval in seconds
          const diffSecounds = Math.abs((dateActuelle.getTime() - savedDate.getTime()) / 1000)
          // Get the interval from APP Params
          const refreshIntervalSec = process.env.VUE_APP_REFRESH_INTERVAL_SEC
          // debugger
          // Convertir la valeur en nombre si nécessaire
          const refreshIntervalSecNumber = refreshIntervalSec ? parseInt(refreshIntervalSec, 10) : 0
          const convertedDuration = convertSecondsInHMS(diffSecounds)
          convertedDuration.secondes += 1
          if (diffSecounds > refreshIntervalSecNumber){

            const convertedDuration = convertSecondsInHMS(diffSecounds)
            const msg = `Dernier chargement des données il y a ${convertedDuration.heures} h. ${convertedDuration.minutes} min.`
            // const alert = await showAlert(
            // 'VD Apprentissage Auto-Refresh Data',
            // msg,
            // [
            //   {
            //     text: 'OK',
            //     handler: () => {
            //       if (!registration || !registration.waiting) return
            //       registration.waiting.postMessage({ type: 'SKIP_WAITING' })
            //     }
            //   }
            // ],
            // false
            // )
            // alert.present()

            await refreshData()
            // reset the date of the last data loading.
            const currentDate = new Date()
            const serialzedDate = currentDate.toJSON()

            // Save the date
            localStorage.setItem('lastDataLoaded', serialzedDate)
          }

        }
      }
    })



    return {
      isLoading,
      isOffline
    }
  }
})
