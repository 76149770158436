
import { defineComponent } from 'vue'
import { IonPage, IonContent, IonGrid, IonRow, IonCol, IonSpinner } from '@ionic/vue'
import AppBar from '@/components/AppBar.vue'

export default defineComponent({
  name: 'AppLoading',
  components: {
    IonPage,
    IonContent,
    IonGrid,
    IonRow,
    IonCol,
    IonSpinner,
    AppBar
  },
  props: {
    offline: {
      type: Boolean,
      default: false
    }
  },
  setup () {
    const errorMessage = 'L\'application n\'est actuellement pas disponible. Veuillez essayer plus tard.'

    return {
      errorMessage
    }
  }
})
