
import { defineComponent, toRefs, ref, computed } from 'vue'
import { IonPage, IonContent, IonGrid, IonRow, IonCol, IonSearchbar, IonList, IonItem, IonAvatar, IonLabel } from '@ionic/vue'
import { searchOutline, closeOutline } from 'ionicons/icons'
import AppBar from '@/components/AppBar.vue'
import useStore from '@/store'

export default defineComponent({
  name: 'HomePage',
  components: {
    IonPage,
    IonContent,
    IonGrid,
    IonRow,
    IonCol,
    IonSearchbar,
    IonList,
    IonItem,
    IonAvatar,
    IonLabel,
    AppBar
  },
  setup () {
    const store = useStore()
    const { apiUrl, messages } = toRefs(store)
    const search = ref('')
    const metiers = computed(() => store.getMetiersBySearch(search.value))

    return {
      searchOutline,
      closeOutline,
      apiUrl,
      messages,
      search,
      metiers
    }
  }
})
