import { createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-4b9a2208"),n=n(),_popScopeId(),n)
const _hoisted_1 = ["alt"]
const _hoisted_2 = { class: "ion-text-wrap custom-primary-font" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ion_title = _resolveComponent("ion-title")!
  const _component_ion_icon = _resolveComponent("ion-icon")!
  const _component_ion_button = _resolveComponent("ion-button")!
  const _component_ion_buttons = _resolveComponent("ion-buttons")!
  const _component_ion_toolbar = _resolveComponent("ion-toolbar")!
  const _component_ion_header = _resolveComponent("ion-header")!

  return (_openBlock(), _createBlock(_component_ion_header, null, {
    default: _withCtx(() => [
      _createVNode(_component_ion_toolbar, { color: "primary" }, {
        default: _withCtx(() => [
          _createVNode(_component_ion_title, null, {
            default: _withCtx(() => [
              _createElementVNode("div", null, [
                _createElementVNode("img", {
                  src: "/assets/logo.png",
                  alt: `Logo ${_ctx.title}`,
                  width: "40"
                }, null, 8, _hoisted_1),
                _createElementVNode("span", _hoisted_2, _toDisplayString(_ctx.title), 1)
              ])
            ]),
            _: 1
          }),
          _createVNode(_component_ion_buttons, { slot: "end" }, {
            default: _withCtx(() => [
              _createVNode(_component_ion_button, { onClick: _ctx.refreshData }, {
                default: _withCtx(() => [
                  _createVNode(_component_ion_icon, {
                    slot: "icon-only",
                    icon: _ctx.refreshOutline
                  }, null, 8, ["icon"])
                ]),
                _: 1
              }, 8, ["onClick"])
            ]),
            _: 1
          })
        ]),
        _: 1
      })
    ]),
    _: 1
  }))
}