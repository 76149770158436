import { createRouter, createWebHistory } from '@ionic/vue-router'
import { RouteRecordRaw } from 'vue-router'
import HomePage from '@/views/HomePage.vue'

const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    name: 'HomePage',
    component: HomePage
  },
  {
    path: '/metier/:metierId',
    name: 'MetierPage',
    component: () => import('@/views/MetierPage.vue')
  },
  {
    path: '/metier/:metierId/aides',
    name: 'AidesPage',
    component: () => import('@/views/AidesPage.vue')
  },
  {
    path: '/metier/:metierId/informations-pratiques',
    name: 'InformationsPratiquesPage',
    component: () => import('@/views/InformationsPratiquesPage.vue')
  },
  {
    path: '/metier/:metierId/informations-pratiques/:informationPratiqueId',
    name: 'InformationsPratiquesContentPage',
    component: () => import('@/views/InformationsPratiquesContentPage.vue')
  },
  {
    path: '/metier/:metierId/informations-pratiques/:informationPratiqueId/:sousInformationPratiqueId',
    name: 'SousInformationsPratiquesPage',
    component: () => import('@/views/SousInformationsPratiquesPage.vue')
  },
  {
    path: '/metier/:metierId/apres-apprentissages',
    name: 'ApresApprentissagesPage',
    component: () => import('@/views/ApresApprentissagesPage.vue')
  },
  {
    path: '/metier/:metierId/apres-apprentissages/:apresApprentissageId',
    name: 'ApresApprentissagesContentPage',
    component: () => import('@/views/ApresApprentissagesContentPage.vue')
  },
  {
    path: '/metier/:metierId/apres-apprentissages/:apresApprentissageId/:sousApresApprentissageId',
    name: 'SousApresApprentissagesPage',
    component: () => import('@/views/SousApresApprentissagesPage.vue')
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
