import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, withCtx as _withCtx, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-3699d190"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "custom-primary-font" }
const _hoisted_2 = { key: 0 }
const _hoisted_3 = ["src"]
const _hoisted_4 = { class: "ion-text-wrap" }
const _hoisted_5 = { key: 1 }
const _hoisted_6 = { class: "ion-text-center" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_AppBar = _resolveComponent("AppBar")!
  const _component_ion_col = _resolveComponent("ion-col")!
  const _component_ion_row = _resolveComponent("ion-row")!
  const _component_ion_searchbar = _resolveComponent("ion-searchbar")!
  const _component_ion_avatar = _resolveComponent("ion-avatar")!
  const _component_ion_label = _resolveComponent("ion-label")!
  const _component_ion_item = _resolveComponent("ion-item")!
  const _component_ion_list = _resolveComponent("ion-list")!
  const _component_ion_grid = _resolveComponent("ion-grid")!
  const _component_ion_content = _resolveComponent("ion-content")!
  const _component_ion_page = _resolveComponent("ion-page")!

  return (_openBlock(), _createBlock(_component_ion_page, null, {
    default: _withCtx(() => [
      _createVNode(_component_AppBar),
      _createVNode(_component_ion_content, null, {
        default: _withCtx(() => [
          _createVNode(_component_ion_grid, null, {
            default: _withCtx(() => [
              _createVNode(_component_ion_row, { class: "ion-justify-content-center" }, {
                default: _withCtx(() => [
                  _createVNode(_component_ion_col, {
                    "size-xs": "12",
                    "size-sm": "10",
                    "size-md": "8",
                    "size-lg": "6",
                    "size-xl": "6"
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_ion_row, null, {
                        default: _withCtx(() => [
                          _createVNode(_component_ion_col, null, {
                            default: _withCtx(() => [
                              _createElementVNode("h1", _hoisted_1, _toDisplayString(_ctx.messages.metierQuestion), 1)
                            ]),
                            _: 1
                          })
                        ]),
                        _: 1
                      }),
                      _createVNode(_component_ion_row, null, {
                        default: _withCtx(() => [
                          _createVNode(_component_ion_col, null, {
                            default: _withCtx(() => [
                              _createVNode(_component_ion_searchbar, {
                                modelValue: _ctx.search,
                                "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.search) = $event)),
                                mode: "ios",
                                color: "light",
                                "search-icon": _ctx.searchOutline,
                                "clear-icon": _ctx.closeOutline,
                                placeholder: _ctx.messages.searchPlaceholder
                              }, null, 8, ["modelValue", "search-icon", "clear-icon", "placeholder"])
                            ]),
                            _: 1
                          })
                        ]),
                        _: 1
                      }),
                      _createVNode(_component_ion_row, null, {
                        default: _withCtx(() => [
                          _createVNode(_component_ion_col, null, {
                            default: _withCtx(() => [
                              _createVNode(_component_ion_list, null, {
                                default: _withCtx(() => [
                                  (_ctx.metiers.length > 0)
                                    ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
                                        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.metiers, (item, index) => {
                                          return (_openBlock(), _createBlock(_component_ion_item, {
                                            key: index,
                                            button: "",
                                            detail: "",
                                            "router-link": `/metier/${item.id}`
                                          }, {
                                            default: _withCtx(() => [
                                              _createVNode(_component_ion_avatar, { slot: "start" }, {
                                                default: _withCtx(() => [
                                                  _createElementVNode("img", {
                                                    src: `${_ctx.apiUrl}assets/${item.image}`
                                                  }, null, 8, _hoisted_3)
                                                ]),
                                                _: 2
                                              }, 1024),
                                              _createVNode(_component_ion_label, null, {
                                                default: _withCtx(() => [
                                                  _createElementVNode("span", _hoisted_4, _toDisplayString(item.name), 1)
                                                ]),
                                                _: 2
                                              }, 1024)
                                            ]),
                                            _: 2
                                          }, 1032, ["router-link"]))
                                        }), 128))
                                      ]))
                                    : (_openBlock(), _createElementBlock("div", _hoisted_5, [
                                        _createElementVNode("p", _hoisted_6, _toDisplayString(_ctx.messages.noMetierFound), 1)
                                      ]))
                                ]),
                                _: 1
                              })
                            ]),
                            _: 1
                          })
                        ]),
                        _: 1
                      })
                    ]),
                    _: 1
                  })
                ]),
                _: 1
              })
            ]),
            _: 1
          })
        ]),
        _: 1
      })
    ]),
    _: 1
  }))
}