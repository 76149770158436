
import { defineComponent, toRefs } from 'vue'
import { IonHeader, IonToolbar, IonTitle, IonButtons, IonButton, IonIcon } from '@ionic/vue'
import { refreshOutline } from 'ionicons/icons'
import useStore from '@/store'
import { showLoading, showToast } from '@/lib/utils'

export default defineComponent({
  name: 'AppBar',
  components: {
    IonHeader,
    IonToolbar,
    IonTitle,
    IonButtons,
    IonButton,
    IonIcon
  },
  setup () {
    const store = useStore()
    const { title } = toRefs(store)

    const refreshData = async () => {
      const loading = await showLoading()
      await loading.present()

      try {
        await store.fetchMessages()
        await store.fetchMetiers()
        await store.fetchAides()
        await store.fetchResponsables()
        await store.fetchInformationsPratiques()
        await store.fetchSousInformationsPratiques()
        await store.fetchApresApprentissages()
        await store.fetchSousApresApprentissages()
      } catch (error) {
        const toast = await showToast(
          'Un problème est survenu.',
          'danger'
        )

        await toast.present()
      } finally {
        loading.dismiss()
      }
    }

    return {
      title,
      refreshOutline,
      refreshData
    }
  }
})
